#players {
  margin: 0 2.78%;
  padding-top: 40px;

  .user-header {
    display: flex;
    justify-content: space-between;

    .title {
      font-size: 1.5rem;
      font-weight: bold;
      color: #6d7278;
      line-height: 1.813rem;
    }

    .search-input {
      width: 26.32%;
    }
  }

  .btn-group {
    display: flex;
    justify-content: center;
  }

  table {
    width: 100%;
  }
}

:global(.btn-group .bp3-icon-flag) {
  color: #f69314;
}

:global(.btn-group .bp3-icon-blocked-person) {
  color: #ff502f;
}

:global(.coming-soon) {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 64px;
  font-size: 1.5rem;
  color: #f69313;
}
