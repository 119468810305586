#login {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  #google-login-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 0 4px 0 grey;
    padding: 8px 16px;
    border-radius: 8px;

    .text {
      padding-left: 8px;
      font-weight: 500;
    }
  }
}
