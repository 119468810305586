#search-player {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .search-input {
    width: 34.72%;
  }

  .not-found-error {
    color: #e02020;
    margin-top: 2.34%;
  }
}
