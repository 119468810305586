#status-panel {
  .player-status {
    position: relative;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 500;
    margin: 0 -40px;

    .cta {
      position: absolute;
      right: 0;
      display: flex;

      .details-btn {
        display: flex;
        align-items: center;
        text-decoration: underline;
        cursor: pointer;
      }

      .cta-btn {
        margin-left: 20px;
        width: 113px;
        height: 28px;
        border: 1px solid #ffffff;
        border-radius: 14px;
        color: white;
        background: transparent;
        font-weight: 500;
        margin-right: 40px;
      }
    }
  }

  .flagged {
    background: #f69314;
  }

  .blocked {
    background: #ff502f;
  }

  .kyc {
    background: #7045af;
  }
}
