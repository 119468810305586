.details-card {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0 2.78%;
  width: 384px;
  height: 545px;
  z-index: 99;
  // box-shadow: 0px 0px 8px 2px grey;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px 8px 0 0;
  background: #f6f6f6;
  padding: 20px;
  display: flex;
  flex-flow: column;

  .header {
    display: flex;
    justify-content: space-between;
    flex: 0 1 auto;

    .title {
      display: flex;
      align-items: center;
      font-size: 0.75rem;
      font-weight: bold;
      color: #6d7278;
    }

    .bp3-button {
      padding: 5px 0;
    }
  }

  .details {
    height: 100%;
    overflow-y: auto;

    .row {
      display: flex;
      flex-direction: column;
      padding-top: 24px;
      padding-bottom: 8px;

      .key {
        color: #6d7278;
        font-weight: bold;
        font-size: 0.75rem;
      }

      .value {
        font-size: 0.875rem;
        word-wrap: break-word;
      }
    }
  }

  .footer {
    display: flex;
    flex: 0 1 auto;
    justify-content: space-between;
    font-size: 0.75rem;
  }
}
