.dashboard-chooser {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .dashboard-selector {
    width: 50%;
    text-align: center;
  }
}
