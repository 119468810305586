.player-profile {
  padding-top: 40px;

  .header {
    display: flex;
    justify-content: space-between;

    .title {
      font-size: 1.5rem;
      font-weight: bold;
      color: #6d7278;
      line-height: 1.813rem;
    }

    .search-input {
      width: 26.32%;
    }

    .back-to-kyc {
      font-weight: 500;
      color: #7045af;
    }
  }

  .player {
    padding-top: 3.91%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
