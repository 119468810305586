#kyc {
  margin: 0 2.78%;
  padding-top: 40px;

  .kyc-header {
    display: flex;
    justify-content: space-between;

    .title {
      font-size: 1.5rem;
      font-weight: bold;
      color: #6d7278;
      line-height: 1.813rem;
    }

    .search-input {
      width: 26.32%;
    }
  }

  .btn-group {
    display: flex;
    justify-content: center;
  }

  table {
    width: 100%;
  }
}
