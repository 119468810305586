.player-card {
  display: flex;
  flex-direction: row;

  $player-dp-size: 100px;
  .player-dp {
    height: $player-dp-size;
    width: $player-dp-size;
    border-radius: $player-dp-size;
    overflow: hidden;

    .player-dp-img {
      width: 100%;
    }
  }

  .player-details {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .name {
      // padding-left: 4px;
      font-size: 1.25rem;
      line-height: 1.5rem;
      font-weight: 500;
    }

    .player-id {
      color: #6d7278;
      font-size: 0.75rem;
      font-weight: 300;
    }

    .profile-details-btn {
      color: #7045af;
      font-size: 0.875rem;
      font-weight: 500;
      cursor: pointer;
      margin-top: 10px;
    }
  }
}
