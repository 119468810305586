:global(#upload-count table) {
  width: 100%;
  margin-top: 28px;
  border-spacing: 1px;
}

:global(#upload-count table thead tr) {
  background: #f0f0f0;
}

:global(#upload-count th) {
  color: #6d7278;
  font-size: 0.75rem;
}

:global(#upload-count table tbody tr) {
  padding: 22px 11px;
  background: #ffebd3;
}

:global(#upload-count .wild-upload-select) {
  width: 60%;
  margin-left: 16px;
}

:global(.wild-upload-action-div) {
  display: flex;
  justify-content: flex-end;
}

:global(#upload-count .increment-wild-upload) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
