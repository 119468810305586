.App {
  height: 100%;
}

:global(.bp3-button) {
  outline: none;
}

:global(.bp3-button .bp3-icon-tick-circle) {
  color: #42b883;
}

:global(.bp3-button .bp3-icon-error) {
  color: #dc2f2f;
}
