.mega-navbar {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);

  .switch {
    cursor: pointer;
  }

  .user-email {
    display: flex;
    align-items: center;
    margin-right: 1rem;
  }

  $user-dp-size: 42px;
  .user-dp {
    width: $user-dp-size;
    height: $user-dp-size;
    border-radius: $user-dp-size;
    margin-right: 4px;
  }

  .navbar-items {
    .navbar-menu-item {
      display: flex;
      align-items: center;
      margin-left: 32px;
      cursor: pointer;
      height: 100%;
      outline: none;
    }

    .navbar-menu-item.selected {
      font-weight: 500;
      border-bottom: 2px #7045af solid;
      color: #7045af;
    }
  }
}
