.dashboard {
  height: 100%;
  display: flex;
  flex-flow: column;

  .mega-header {
    flex: 0 1 auto;
  }

  .content {
    height: 100%;
  }
}
