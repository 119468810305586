.info-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;

  .title {
    color: #6d7278;
    font-size: 0.75rem;
    font-weight: bold;
    padding-bottom: 0.75rem;
  }

  .value {
    font-size: 1.25rem;
    font-weight: 500;
  }
}
