.flag-or-block-details {
  padding-bottom: 0;

  .title {
    font-size: 1.5rem;
    color: #6d7278;
    font-weight: bold;
    margin-bottom: 28px;
  }

  .reason-text {
    color: #6d7278;
    font-size: 0.875rem;
    font-weight: bold;
    margin-bottom: 16px;
  }

  .icon {
    color: #6d7278;
    margin-right: 8px;
  }

  .reason {
    margin-bottom: 12px;
  }
}
