.profile-dialog {
  .player-card {
    display: flex;
    flex-direction: row;

    .player-dp {
      height: 60px;
      width: 60px;
      border-radius: 30px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .player-details {
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .name {
        // padding-left: 4px;
        font-size: 1.25rem;
        line-height: 1.5rem;
        font-weight: 500;
      }

      .player-id {
        color: #6d7278;
        font-size: 0.75rem;
        font-weight: 300;
      }
    }
  }

  .location,
  .email,
  .android-version,
  .tags {
    margin-top: 32px;

    .icon {
      color: #6d7278;
      margin-right: 4px;
    }

    .text-span {
      padding-left: 4px;
    }
  }

  .location {
    margin-top: 32px;
  }

  .tags {
    display: flex;
    flex-direction: column;

    .text-div {
      // font-size: 0.75rem;
      color: #6d7278;
      font-weight: bold;
    }

    .tags-list {
      display: flex;
      flex-wrap: wrap;

      .tag {
        margin: 4px;
      }
    }
  }
}
