#loading {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(128, 128, 128, 0.54);
  color: #db3737;
  font-size: 1.25rem;
  font-weight: 500;
}
