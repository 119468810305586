.mega-grid {
  overflow-x: auto;

  .mega-filter {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 1rem;
  }

  // .filter-btn,
  // .filter-btn svg {
  //   color: #7045af;
  // }

  .group-tr th {
    padding: 24px 0 8px 11px;
  }

  .mega-grid-table {
    border-spacing: 1px;
    width: 100%;

    tbody tr:first-child th {
      box-shadow: unset;
    }

    tr.unselected {
      background: #ffebd3;
    }

    tr.selected {
      background: #b9b9b9;
    }

    .no-data {
      font-size: 1.25rem;
      font-weight: 500;
      text-align: center;
      color: #de4748;
      padding-top: 32px;
    }
  }
}

.cell {
  color: #6d7278 !important;
  padding: 22px 8px !important;
}

:global(.formattedTime) {
  width: 86px;
}
