#user-kyc {
  padding-bottom: 32px;
  #details-by-user,
  #details-by-ocr,
  #check-status,
  #failure-reason,
  #document-images {
    padding: 40px 20px 32px 20px;

    .text {
      font-size: 0.875rem;
      color: #6d7278;
      font-weight: bold;
      margin-bottom: 16px;
    }

    .details {
      display: flex;

      .detail {
        display: flex;
        flex-direction: column;
        width: 33.33%;
        padding: 0 32px 0 0;

        .label {
          text-transform: uppercase;
          font-size: 0.75rem;
          color: #6d7278;
          font-weight: bold;
        }
      }
    }
  }

  #details-by-ocr {
    background: #f0f0f0;

    .details {
      flex-wrap: wrap;

      .detail {
        padding: 8px 0;
      }
    }
  }

  #check-status {
    padding: 40px 20px;

    .statuses {
      display: flex;

      .status {
        font-size: 0.875rem;
        font-weight: bold;
        width: 33.33%;

        .status-icon {
          margin-right: 8px;
        }
      }
    }
  }

  #failure-reason {
    .text {
      margin-bottom: 0;
    }

    .check-name {
      font-weight: 500;
    }
  }

  #action-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    .send-btn {
      margin-top: 16px;
      width: 113px;
      border-radius: 14px;
      // color: white;
      font-weight: 500;
      // background: #7045af;
    }
  }
}

#document-images {
  display: flex;
  padding: 0 20px;

  .document-image-div {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    color: #6d7278;
    padding-right: 40px;
    cursor: pointer;

    .document-image {
      width: 381px;
      height: 291px;
    }
  }
}

.comment {
  width: 681px;
  height: 146px !important;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  background-color: #ebebeb;
}

:global(#kyc-btn-group) {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

:global(#kyc-btn-group button) {
  min-width: 115px;
  // background: #7045af;
  color: #7045af;
  font-weight: 500;
  font-size: 0.75rem;
  box-shadow: inset 0 0 0 1px #7045af, inset 0 -1px 0 #7045af;
}

:global(#kyc-btn-group .round-left) {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

:global(#kyc-btn-group .round-right) {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

:global(#kyc-btn-group .selected) {
  background: #7045af;
  color: white;
}

.user-status-text {
  padding: 16px;
}
