.flag-or-block-user {
  padding-bottom: 0;

  .title {
    text-transform: capitalize;
    color: #6d7278;
    font-size: 1.5rem;
    font-weight: bold;
  }

  .reason {
    font-size: 0.75rem;
    font-weight: bold;
    color: #6d7278;
    margin-top: 28px;
  }

  .reason-list {
    margin-top: 16px;
  }

  .btn-div {
    margin-top: 64px;
    display: flex;
    justify-content: center;

    .action-btn {
      border-radius: 30px;
      text-transform: capitalize;
      font-weight: 500;
    }
  }
}
