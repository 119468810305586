.mega-button-group {
  .btn {
    text-transform: capitalize;
    color: #7045af;
    font-size: 0.75rem;
    font-weight: 500;
    width: 100%;
    background: white;

    .tag {
      font-size: 0.625rem;
      min-height: 20px;
      margin-left: 8px;
    }
  }

  .btn.selected {
    background: #7a7a7a;
    color: white;
  }

  $border-radius: 8px;
  .round-left {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  .round-right {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
