@import '~normalize.css';
@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~@blueprintjs/core/lib/scss/variables.scss';
@import '~@blueprintjs/datetime/lib/css/blueprint-datetime.css';

@font-face {
  font-family: 'Gotham Rounded';
  src: url('./assets/fonts/gothamroundedbold.woff')
    url('./assets/fonts/gothamroundedbook.woff')
    url('./assets/fonts/gothamroundedlight.woff')
    url('./assets/fonts/gothamroundedmedium.woff');
  font-display: swap;
}

body {
  margin: 0;
  height: 100vh;
  font-family: 'Gotham Rounded', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f6f6f6;

  padding-top: $pt-navbar-height;

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  #root {
    height: 100%;
  }
}
